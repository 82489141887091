/**
 * outline styles
 */
.djs-outline {
  fill: none;
  visibility: hidden;
}
.djs-element.hover .djs-outline,
.djs-element.selected .djs-outline {
  visibility: visible;
  shape-rendering: crispEdges;
  stroke-dasharray: 3,3;
}
.djs-element.selected .djs-outline {
  stroke: #8888FF;
  stroke-width: 1px;
}
.djs-element.hover .djs-outline {
  stroke: #FF8888;
  stroke-width: 1px;
}
.djs-shape.connect-ok .djs-visual > :nth-child(1) {
  fill: #DCFECC /* light-green */ !important;
}
.djs-shape.connect-not-ok .djs-visual > :nth-child(1),
.djs-shape.drop-not-ok .djs-visual > :nth-child(1) {
  fill: #f9dee5 /* light-red */ !important;
}
.djs-shape.new-parent .djs-visual > :nth-child(1) {
  fill: #F7F9FF !important;
}
svg.drop-not-ok {
  background: #f9dee5 /* light-red */ !important;
}
svg.new-parent {
  background: #F7F9FF /* light-blue */ !important;
}
.djs-connection.connect-ok .djs-visual > :nth-child(1),
.djs-connection.drop-ok .djs-visual > :nth-child(1) {
  stroke: #90DD5F /* light-green */ !important;
}
.djs-connection.connect-not-ok .djs-visual > :nth-child(1),
.djs-connection.drop-not-ok .djs-visual > :nth-child(1) {
  stroke: #E56283 /* light-red */ !important;
}
.drop-not-ok,
.connect-not-ok {
  cursor: not-allowed;
}
.djs-element.attach-ok .djs-visual > :nth-child(1) {
  stroke-width: 5px !important;
  stroke: rgba(255, 116, 0, 0.7) !important;
}
.djs-frame.connect-not-ok .djs-visual > :nth-child(1),
.djs-frame.drop-not-ok .djs-visual > :nth-child(1) {
  stroke-width: 3px !important;
  stroke: #E56283 /* light-red */ !important;
  fill: none !important;
}
/**
* Selection box style
*
*/
.djs-lasso-overlay {
  fill: #ff7400;
  fill-opacity: 0.1;
  stroke-dasharray: 5 1 3 1;
  stroke: #ff7400;
  shape-rendering: crispEdges;
  pointer-events: none;
}
/**
 * Resize styles
 */
.djs-resize-overlay {
  fill: none;
  stroke-dasharray: 5 1 3 1;
  stroke: #ff7400;
  pointer-events: none;
}
.djs-resizer-hit {
  fill: none;
  pointer-events: all;
}
.djs-resizer-visual {
  fill: white;
  stroke-width: 1px;
  stroke: #BBB;
  shape-rendering: geometricprecision;
}
.djs-resizer:hover .djs-resizer-visual {
  stroke: #555;
}
.djs-cursor-resize-ns,
.djs-resizer-n,
.djs-resizer-s {
  cursor: ns-resize;
}
.djs-cursor-resize-ew,
.djs-resizer-e,
.djs-resizer-w {
  cursor: ew-resize;
}
.djs-cursor-resize-nwse,
.djs-resizer-nw,
.djs-resizer-se {
  cursor: nwse-resize;
}
.djs-cursor-resize-nesw,
.djs-resizer-ne,
.djs-resizer-sw {
  cursor: nesw-resize;
}
.djs-shape.djs-resizing > .djs-outline {
  visibility: hidden !important;
}
.djs-shape.djs-resizing > .djs-resizer {
  visibility: hidden;
}
.djs-dragger > .djs-resizer {
  visibility: hidden;
}
/**
 * drag styles
 */
.djs-dragger * {
  fill: none !important;
  stroke: #ff7400 !important;
}
.djs-dragger tspan,
.djs-dragger text {
  fill: #ff7400 !important;
  stroke: none !important;
}
marker.djs-dragger circle,
marker.djs-dragger path,
marker.djs-dragger polygon,
marker.djs-dragger polyline,
marker.djs-dragger rect {
  fill: #ff7400 !important;
  stroke: none !important;
}
marker.djs-dragger text,
marker.djs-dragger tspan {
  fill: none !important;
  stroke: #ff7400 !important;
}
.djs-dragging {
  opacity: 0.3;
}
.djs-dragging,
.djs-dragging > * {
  pointer-events: none !important;
}
.djs-dragging .djs-context-pad,
.djs-dragging .djs-outline {
  display: none !important;
}
/**
 * no pointer events for visual
 */
.djs-visual,
.djs-outline {
  pointer-events: none;
}
.djs-element.attach-ok .djs-hit {
  stroke-width: 60px !important;
}
/**
 * all pointer events for hit shape
 */
.djs-element > .djs-hit-all {
  pointer-events: all;
}
.djs-element > .djs-hit-stroke,
.djs-element > .djs-hit-click-stroke {
  pointer-events: stroke;
}
/**
 * all pointer events for hit shape
 */
.djs-drag-active .djs-element > .djs-hit-click-stroke {
  pointer-events: all;
}
/**
 * shape / connection basic styles
 */
.djs-connection .djs-visual {
  stroke-width: 2px;
  fill: none;
}
.djs-cursor-grab {
  cursor: grab;
}
.djs-cursor-grabbing {
  cursor: grabbing;
}
.djs-cursor-crosshair {
  cursor: crosshair;
}
.djs-cursor-move {
  cursor: move;
}
.djs-cursor-resize-ns {
  cursor: ns-resize;
}
.djs-cursor-resize-ew {
  cursor: ew-resize;
}
/**
 * snapping
 */
.djs-snap-line {
  stroke: #ffc342;
  stroke: rgba(255, 195, 66, 0.5);
  stroke-linecap: round;
  stroke-width: 2px;
  pointer-events: none;
}
/**
 * snapping
 */
.djs-crosshair {
  stroke: #555;
  stroke-linecap: round;
  stroke-width: 1px;
  pointer-events: none;
  shape-rendering: crispEdges;
  stroke-dasharray: 5, 5;
}
/**
 * palette
 */
.djs-palette {
  position: absolute;
  left: 20px;
  top: 20px;
  box-sizing: border-box;
  width: 48px;
}
.djs-palette .separator {
  margin: 0 5px;
  padding-top: 5px;
  border: none;
  border-bottom: solid 1px #DDD;
  clear: both;
}
.djs-palette .entry:before {
  vertical-align: text-bottom;
}
.djs-palette .djs-palette-toggle {
  cursor: pointer;
}
.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  color: #333;
  font-size: 30px;
  text-align: center;
}
.djs-palette .entry {
  float: left;
}
.djs-palette .entry img {
  max-width: 100%;
}
.djs-palette .djs-palette-entries:after {
  content: '';
  display: table;
  clear: both;
}
.djs-palette .djs-palette-toggle:hover {
  background: #666;
}
.djs-palette .entry:hover {
  color: #ff7400;
}
.djs-palette .highlighted-entry {
  color: #ff7400 !important;
}
.djs-palette .entry,
.djs-palette .djs-palette-toggle {
  width: 46px;
  height: 46px;
  line-height: 46px;
  cursor: default;
}
/**
 * Palette open / two-column layout is controlled via
 * classes on the palette. Events to hook into palette
 * changed life-cycle are available in addition.
 */
.djs-palette.two-column.open {
  width: 94px;
}
.djs-palette:not(.open) .djs-palette-entries {
  display: none;
}
.djs-palette:not(.open) {
  overflow: hidden;
}
.djs-palette.open .djs-palette-toggle {
  display: none;
}
/**
 * context-pad
 */
.djs-overlay-context-pad {
  width: 72px;
}
.djs-context-pad {
  position: absolute;
  display: none;
  pointer-events: none;
}
.djs-context-pad .entry {
  width: 22px;
  height: 22px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  margin: 0 2px 2px 0;
  border-radius: 3px;
  cursor: default;
  background-color: #FEFEFE;
  box-shadow: 0 0 2px 1px #FEFEFE;
  pointer-events: all;
}
.djs-context-pad .entry:before {
  vertical-align: top;
}
.djs-context-pad .entry:hover {
  background: #fffcb0;
}
.djs-context-pad.open {
  display: block;
}
/**
 * popup styles
 */
.djs-popup .entry {
  line-height: 20px;
  white-space: nowrap;
  cursor: default;
}
/* larger font for prefixed icons */
.djs-popup .entry:before {
  vertical-align: middle;
  font-size: 20px;
}
.djs-popup .entry > span {
  vertical-align: middle;
  font-size: 14px;
}
.djs-popup .entry:hover,
.djs-popup .entry.active:hover {
  background: #fffcb0;
}
.djs-popup .entry.disabled {
  background: inherit;
}
.djs-popup .djs-popup-header .entry {
  display: inline-block;
  padding: 2px 3px 2px 3px;
  border: solid 1px transparent;
  border-radius: 3px;
}
.djs-popup .djs-popup-header .entry.active {
  color: #ff7400;
  border: solid 1px #ff7400;
  background-color: #F6F6F6;
}
.djs-popup-body .entry {
  padding: 4px 10px 4px 5px;
}
.djs-popup-body .entry > span {
  margin-left: 5px;
}
.djs-popup-body {
  background-color: #FEFEFE;
}
.djs-popup-header {
  border-bottom: 1px solid #DDD;
}
.djs-popup-header .entry {
  margin: 1px;
  margin-left: 3px;
}
.djs-popup-header .entry:last-child {
  margin-right: 3px;
}
/**
 * popup / palette styles
 */
.djs-popup,
.djs-palette {
  background: #FAFAFA;
  border: solid 1px #CCC;
  border-radius: 2px;
}
/**
 * touch
 */
.djs-shape,
.djs-connection {
  touch-action: none;
}
.djs-segment-dragger,
.djs-bendpoint {
  display: none;
}
/**
 * bendpoints
 */
.djs-segment-dragger .djs-visual {
  fill: rgba(255, 255, 121, 0.2);
  stroke-width: 1px;
  stroke-opacity: 1;
  stroke: rgba(255, 255, 121, 0.3);
}
.djs-bendpoint .djs-visual {
  fill: rgba(255, 255, 121, 0.8);
  stroke-width: 1px;
  stroke-opacity: 0.5;
  stroke: black;
}
.djs-segment-dragger:hover,
.djs-bendpoints.hover .djs-segment-dragger,
.djs-bendpoints.selected .djs-segment-dragger,
.djs-bendpoint:hover,
.djs-bendpoints.hover .djs-bendpoint,
.djs-bendpoints.selected .djs-bendpoint {
  display: block;
}
.djs-drag-active .djs-bendpoints * {
  display: none;
}
.djs-bendpoints:not(.hover) .floating {
  display: none;
}
.djs-segment-dragger:hover .djs-visual,
.djs-segment-dragger.djs-dragging .djs-visual,
.djs-bendpoint:hover .djs-visual,
.djs-bendpoint.floating .djs-visual {
  fill: yellow;
  stroke-opacity: 0.5;
  stroke: black;
}
.djs-bendpoint.floating .djs-hit {
  pointer-events: none;
}
.djs-segment-dragger .djs-hit,
.djs-bendpoint .djs-hit {
  pointer-events: all;
  fill: none;
}
.djs-segment-dragger.horizontal .djs-hit {
  cursor: ns-resize;
}
.djs-segment-dragger.vertical .djs-hit {
  cursor: ew-resize;
}
.djs-segment-dragger.djs-dragging .djs-hit {
  pointer-events: none;
}
.djs-updating,
.djs-updating > * {
  pointer-events: none !important;
}
.djs-updating .djs-context-pad,
.djs-updating .djs-outline,
.djs-updating .djs-bendpoint,
.connect-ok .djs-bendpoint,
.connect-not-ok .djs-bendpoint,
.drop-ok .djs-bendpoint,
.drop-not-ok .djs-bendpoint {
  display: none !important;
}
.djs-segment-dragger.djs-dragging,
.djs-bendpoint.djs-dragging {
  display: block;
  opacity: 1;
}
.djs-segment-dragger.djs-dragging .djs-visual,
.djs-bendpoint.djs-dragging .djs-visual {
  fill: yellow;
  stroke-opacity: 0.5;
}
/**
 * tooltips
 */
.djs-tooltip-error {
  font-size: 11px;
  line-height: 18px;
  text-align: left;
  padding: 5px;
  opacity: 0.7;
}
.djs-tooltip-error > * {
  width: 160px;
  background: #fcecf0;
  color: #9e4c4c;
  padding: 3px 7px;
  border-radius: 5px;
  border-left: solid 5px #ae4949;
}
.djs-tooltip-error:hover {
  opacity: 1;
}
/**
 * search pad
 */
.djs-search-container {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 25%;
  min-width: 300px;
  max-width: 400px;
  z-index: 10;
  font-size: 1.05em;
  opacity: 0.9;
  background: #FAFAFA;
  border: solid 1px #CCC;
  border-radius: 2px;
}
.djs-search-container:not(.open) {
  display: none;
}
.djs-search-input input {
  font-size: 1.05em;
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #ccc;
}
.djs-search-input input:focus {
  outline: none;
  border-color: #52B415;
}
.djs-search-results {
  position: relative;
  overflow-y: auto;
  max-height: 200px;
}
.djs-search-results:hover {
  /*background: #fffdd7;*/
  cursor: pointer;
}
.djs-search-result {
  width: 100%;
  padding: 6px 10px;
  background: white;
  border-bottom: solid 1px #AAA;
  border-radius: 1px;
}
.djs-search-highlight {
  color: black;
}
.djs-search-result-primary {
  margin: 0 0 10px;
}
.djs-search-result-secondary {
  font-family: monospace;
  margin: 0;
}
.djs-search-result:hover {
  background: #fdffd6;
}
.djs-search-result-selected {
  background: #fffcb0;
}
.djs-search-result-selected:hover {
  background: #f7f388;
}
.djs-search-overlay {
  background: yellow;
  opacity: 0.3;
}
/**
 * hidden styles
 */
.djs-element-hidden,
.djs-element-hidden .djs-hit,
.djs-element-hidden .djs-outline,
.djs-label-hidden .djs-label {
  display: none !important;
}
